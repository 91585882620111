import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminUsers: (state: AdminState) => state.users,
    adminOneUser: (state: AdminState) => (userId: number) => {
        const filteredUsers = state.users.filter((user) => user.id === userId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },
    adminCategories: (state: AdminState) => state.categories,
    adminOneCategory: (state: AdminState) => (categoryId: number) => {
        const filteredCategories = state.categories.filter((category) => category.id === categoryId);
        if (filteredCategories.length > 0) {
            return { ...filteredCategories[0] };
        }
    },

    adminVideos: (state: AdminState) => state.videos,
    adminOneVideo: (state: AdminState) => (videoId: number) => {
        const filteredVideos = state.videos.filter((video) => video.id === videoId);
        if (filteredVideos.length > 0) {
            return { ...filteredVideos[0] };
        }
    },

    adminSubs: (state: AdminState) => state.subs,
    adminOneSub: (state: AdminState) => (subId: number) => {
        const filteredSubs = state.subs.filter((sub) => sub.id === subId);
        if (filteredSubs.length > 0) {
            return { ...filteredSubs[0] };
        }
    },

    adminNews: (state: AdminState) => state.news,
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
export const readAdminCategories = read(getters.adminCategories);
export const readAdminOneCategory = read(getters.adminOneCategory);
export const readAdminVideos = read(getters.adminVideos);
export const readAdminOneVideo = read(getters.adminOneVideo);
export const readAdminSubs = read(getters.adminSubs);
export const readAdminOneSub = read(getters.adminOneSub);
export const readAdminNews = read(getters.adminNews);
