import { IUserProfile, ICategory, IVideo, INews, ISub } from '@/interfaces';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const mutations = {
    setUsers(state: AdminState, payload: IUserProfile[]) {
        state.users = payload;
    },
    setUser(state: AdminState, payload: IUserProfile) {
        const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
        users.push(payload);
        state.users = users;
    },

    // categories
    setCategories(state: AdminState, payload: ICategory[]) {
        state.categories = payload;
    },
    setCategory(state: AdminState, payload: ICategory) {
        const categories = state.categories.filter((category: ICategory) => category.id !== payload.id);
        categories.push(payload);
        state.categories = categories;
    },
    deleteCategory(state: AdminState, categoryID: number) {
        state.categories = state.categories.filter((category: ICategory) => category.id !== categoryID);
    },
    // videos
    setVideos(state: AdminState, payload: IVideo[]) {
        state.videos = payload;
    },
    setVideo(state: AdminState, payload: IVideo) {
        const videos = state.videos.filter((Video: IVideo) => Video.id !== payload.id);
        videos.push(payload);
        state.videos = videos;
    },
    deleteVideo(state: AdminState, VideoID: number) {
        state.videos = state.videos.filter((Video: IVideo) => Video.id !== VideoID);
    },
    // subs
    setSubs(state: AdminState, payload: ISub[]) {
        state.subs = payload;
    },
    setSub(state: AdminState, payload: ISub) {
        const subs = state.subs.filter((Sub: ISub) => Sub.id !== payload.id);
        subs.push(payload);
        state.subs = subs;
    },
    deleteSub(state: AdminState, SubID: number) {
        state.subs = state.subs.filter((Sub: ISub) => Sub.id !== SubID);
    },
    setNews(state: AdminState, news: INews[]) {
        state.news = news;
    },
};

const { commit } = getStoreAccessors<AdminState, State>('');

export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers);

export const commitSetCategory = commit(mutations.setCategory);
export const commitSetCategories = commit(mutations.setCategories);
export const commitDeleteCategory = commit(mutations.deleteCategory);

export const commitSetVideo = commit(mutations.setVideo);
export const commitSetVideos = commit(mutations.setVideos);
export const commitDeleteVideo = commit(mutations.deleteVideo);

export const commitSetSub = commit(mutations.setSub);
export const commitSetSubs = commit(mutations.setSubs);
export const commitDeleteSub = commit(mutations.deleteSub);

export const commitSetNews = commit(mutations.setNews);
