import { api } from '@/api';
import { ActionContext } from 'vuex';
import {
    IUserProfileCreate, IUserProfileUpdate, ICategoryCreate, ICategoryUpdate, IVideoCreate, IVideoUpdate,
    INews, ISubCreate, ISubUpdate, IUpload,
} from '@/interfaces';
import { State } from '../state';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { commitSetUsers, commitSetUser, commitSetCategory, commitSetCategories, commitDeleteCategory, commitSetVideo,
  commitSetVideos, commitDeleteVideo, commitSetNews, commitSetSub, commitDeleteSub, commitSetSubs} from './mutations';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';

type MainContext = ActionContext<AdminState, State>;

async function send_request_with_notification(context, request, action, msg) {
    // Utility function, which calls request, then action with the response of request, then displays msg.
    try {
        const loadingNotification = { content: 'saving', showProgress: true };
        commitAddNotification(context, loadingNotification);
        const response = (await Promise.all([
            request(),
            await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
        ]))[0];
        action(response);
        commitRemoveNotification(context, loadingNotification);
        commitAddNotification(context, { content: msg, color: 'success' });
    } catch (error) {
        await dispatchCheckApiError(context, error);
    }
}


export const actions = {
    async actionGetUsers(context: MainContext) {
        try {
            const response = await api.getUsers(context.rootState.main.token);
            if (response) {
                commitSetUsers(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUser(context: MainContext, payload: { id: number, user: IUserProfileUpdate }) {
        await send_request_with_notification(context, () => {
            return api.updateUser(context.rootState.main.token, payload.id, payload.user);
        }, (response) => {
            commitSetUser(context, response.data);
        }, 'User successfully updated');
    },
    async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
        await send_request_with_notification(context, () => {
            return api.createUser(context.rootState.main.token, payload);
        }, (response) => {
            commitSetUser(context, response.data);
            },
          'User successfully created');
    },
    // categories
    async actionGetCategories(context: MainContext) {
        try {
            const response = await api.getCategories(context.rootState.main.token);
            if (response) {
                commitSetCategories(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateCategory(context: MainContext, payload: { id: number, category: ICategoryUpdate }) {
        await send_request_with_notification(context, () => {
              return api.updateCategory(context.rootState.main.token, payload.id, payload.category);
          }, (response) => {
              commitSetCategory(context, response.data);
          },
          'Category successfully updated');
    },
    async actionCreateCategory(context: MainContext, payload: ICategoryCreate) {
        await send_request_with_notification(context, () => {
              return api.createCategory(context.rootState.main.token, payload);
          }, (response) => {
              commitSetCategory(context, response.data);
          },
          'Category successfully created');
    },
    async actionDeleteCategory(context: MainContext, id: number) {
        await send_request_with_notification(context, () => {
              return api.deleteCategory(context.rootState.main.token, id);
          }, (response) => {
              commitDeleteCategory(context, id);
          },
          'Category successfully deleted');
    },

    // videos
    async actionGetVideos(context: MainContext) {
      try {
        const response = await api.getVideos(context.rootState.main.token);
        if (response) {
          commitSetVideos(context, response.data);
        }
      } catch (error) {
        await dispatchCheckApiError(context, error);
      }
    },
    async actionUpdateVideo(context: MainContext, payload: { id: number, video: IVideoUpdate }) {
        await send_request_with_notification(context, () => {
              return api.updateVideo(context.rootState.main.token, payload.id, payload.video);
          }, (response) => {
              commitSetVideo(context, response.data);
          },
          'Video successfully updated');
    },
    async actionCreateVideo(context: MainContext, payload: IVideoCreate) {
        await send_request_with_notification(context, () => {
              return api.createVideo(context.rootState.main.token, payload);
          }, (response) => {
              commitSetVideo(context, response.data);
          },
          'Video successfully created');
    },
    async actionDeleteVideo(context: MainContext, id: number) {
        await send_request_with_notification(context, () => {
              return api.deleteVideo(context.rootState.main.token, id);
          }, (response) => {
              commitDeleteVideo(context, id);
          },
          'Video successfully deleted');
    },

    async actionGetSubs(context: MainContext) {
        try {
            const response = await api.getSubs(context.rootState.main.token);
            if (response) {
                commitSetSubs(context, response.data);
            }
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateSub(context: MainContext, payload: { id: number, sub: ISubUpdate }) {
        await send_request_with_notification(context, () => {
                return api.updateSub(context.rootState.main.token, payload.id, payload.sub);
            }, (response) => {
                commitSetSub(context, response.data);
            },
            'Sub successfully updated');
    },
    async actionCreateSub(context: MainContext, payload: ISubCreate) {
        await send_request_with_notification(context, () => {
                return api.createSub(context.rootState.main.token, payload);
            }, (response) => {
                commitSetSub(context, response.data);
            },
            'Sub successfully created');
    },
    async actionDeleteSub(context: MainContext, id: number) {
        await send_request_with_notification(context, () => {
                return api.deleteSub(context.rootState.main.token, id);
            }, (response) => {
                commitDeleteSub(context, id);
            },
            'Sub successfully deleted');
    },
    async actionGetNews(context: MainContext) {
        try {
            const response = await api.getNews(context.rootState.main.token);
            if (response) {
              commitSetNews(context, response.data);
            }
          } catch (error) {
            await dispatchCheckApiError(context, error);
          }
    },

    async actionSetNews(context: MainContext, payload: INews[]) {
        await send_request_with_notification(context, () => {
              return api.setNews(context.rootState.main.token, payload);
          }, (response) => {
              commitSetNews(context, response.data);
          },
          'News successfully updated');
    },
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);

export const dispatchCreateCategory = dispatch(actions.actionCreateCategory);
export const dispatchGetCategories = dispatch(actions.actionGetCategories);
export const dispatchUpdateCategory = dispatch(actions.actionUpdateCategory);
export const dispatchDeleteCategory = dispatch(actions.actionDeleteCategory);

export const dispatchCreateVideo = dispatch(actions.actionCreateVideo);
export const dispatchGetVideos = dispatch(actions.actionGetVideos);
export const dispatchUpdateVideo = dispatch(actions.actionUpdateVideo);
export const dispatchDeleteVideo = dispatch(actions.actionDeleteVideo);

export const dispatchCreateSub = dispatch(actions.actionCreateSub);
export const dispatchGetSubs = dispatch(actions.actionGetSubs);
export const dispatchUpdateSub = dispatch(actions.actionUpdateSub);
export const dispatchDeleteSub = dispatch(actions.actionDeleteSub);

export const dispatchGetNews = dispatch(actions.actionGetNews);
export const dispatchSetNews = dispatch(actions.actionSetNews);
