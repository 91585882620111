import axios from 'axios';
import { apiUrl } from '@/env';
import { IUserProfile, IUserProfileUpdate, IUserProfileCreate,
  ICategory, ICategoryUpdate, ICategoryCreate,
  IVideo, IVideoUpdate, IVideoCreate, INews,
  ISub, ISubUpdate, ISubCreate,
} from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/?limit=10000`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async getCategories(token: string) {
    return axios.get<ICategory[]>(`${apiUrl}/api/v1/categories/?limit=10000`, authHeaders(token));
  },
  async updateCategory(token: string, userId: number, data: ICategoryUpdate) {
    return axios.put(`${apiUrl}/api/v1/categories/${userId}`, data, authHeaders(token));
  },
  async createCategory(token: string, data: ICategoryCreate) {
    return axios.post(`${apiUrl}/api/v1/categories/`, data, authHeaders(token));
  },
  async deleteCategory(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/categories/${id}`, authHeaders(token));
  },
  async getVideos(token: string) {
    return axios.get<IVideo[]>(`${apiUrl}/api/v1/videos/?limit=10000`, authHeaders(token));
  },
  async updateVideo(token: string, userId: number, data: IVideoUpdate) {
    return axios.put(`${apiUrl}/api/v1/videos/${userId}`, data, authHeaders(token));
  },
  async createVideo(token: string, data: IVideoCreate) {
    return axios.post(`${apiUrl}/api/v1/videos/`, data, authHeaders(token));
  },
  async deleteVideo(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/videos/${id}`, authHeaders(token));
  },
  async getSubs(token: string) {
    return axios.get<ISub[]>(`${apiUrl}/api/v1/subscriptions/?limit=10000`, authHeaders(token));
  },
  async updateSub(token: string, userId: number, data: ISubUpdate) {
    return axios.put(`${apiUrl}/api/v1/subscriptions/${userId}`, data, authHeaders(token));
  },
  async createSub(token: string, data: ISubCreate) {
    return axios.post(`${apiUrl}/api/v1/subscriptions/`, data, authHeaders(token));
  },
  async deleteSub(token: string, id: number) {
    return axios.delete(`${apiUrl}/api/v1/subscriptions/${id}`, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  async getNews(token: string) {
    return axios.get(`${apiUrl}/api/v1/news/`, authHeaders(token));
  },
  async setNews(token: string, payload: INews[]) {
    return axios.post(`${apiUrl}/api/v1/news/`, payload, authHeaders(token));
  },

  async uploadFile(token: string, file: File, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`${apiUrl}/api/v1/importer/upload?import_type=${type}`,
        formData, authHeaders(token),
    );
  },

  async validateFile(token: string, file: File, type: string) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(`${apiUrl}/api/v1/importer/validate?import_type=${type}`,
        formData, authHeaders(token),
    );
  },

  async loadVideoThumbnails(token: string) {
    return axios.post(`${apiUrl}/api/v1/utils/load-thumbnails?load_all=false&width=640`, {}, authHeaders(token));
  },

  async loadVideoUrls(token: string) {
    return axios.post(`${apiUrl}/api/v1/utils/load-video-urls?load_all=false`, {}, authHeaders(token));
  },

  async calculateLevels(token: string) {
    return axios.post(`${apiUrl}/api/v1/utils/recalculate-levels`, {}, authHeaders(token));
  },

  async calculateDiscover(token: string) {
    return axios.post(`${apiUrl}/api/v1/utils/calculate-discover`, {}, authHeaders(token));
  },
};
